
import Cloudfun, { defineComponent, ref, reactive, watch, computed, onMounted } from "@cloudfun/core";
import type { Project } from "@/models/data";
import { helper } from "@/utils/helper"
import CfGrid, { GridOptions, GridColumnOptions } from "@/components/grid/Main.vue";
import CfTabs from "@/components/tabs/Main.vue";
import CfSearchBar from "@/components/search-bar/Main.vue";
import ConfirmRemoveModal from "@/components/modal/confirm-remove/Main.vue";
import CfModal from "@/components/modal/Main.vue";
import CheckBoxList from "@/components/checkbox-list/Main.vue";
import { VxeFormProps } from "vxe-table";

export default defineComponent({
  components: {
    // CfTabsBar,
    CfGrid,
    CfTabs,
    CfSearchBar,
    CfModal,
    ConfirmRemoveModal,
    CheckBoxList
  },
  setup() {
    const model = Cloudfun.current?.model;
    const permissions: any = Cloudfun.current?.user.Permissions;
    const grid = ref<any>();
    const selectedRow = ref<Project | null>(null);
    const tabs: any = ref([])

    const gridOptions: GridOptions = {
      columns: [
      { field: 'CreatedTime', title: 'Time', width: 200, sortable: true, formatter({ row }) { return helper.formatDate(row.CreatedTime, "yyyy/MM/dd HH:mm") } },
        { field: 'UserName', title: 'User', width: 100, sortable: true },
        { field: 'ActionName', title: 'Action', width: 150,},
        { field: 'EntityTypeName', title: 'Type' },
        { field: 'EntityName', title: 'Name', width: 100 }
      ],
      promises: {
        query: model
          ? (params) => new Promise((resolve) => {
            if (grid.value.keyword) params.keyword = grid.value.keyword
            params.sortings = params.sortings || [];
            params.pageSize = 20;
            model.dispatch('actionLog/query', params).then(resp => {
              resolve(resp);
            });
          }) : undefined,
      }
    }

    // const onGridCellClick = ({ row, column }: { row: Project, column: GridColumnOptions }) => {
    //   if (column.field === "Name") {
    //     router?.push("/project/" + row.Id);
    //     return;
    //   }
    //   selectedRow.value = row;
    // }

    const onSearch = (value: string) => {
      console.log(value);
      grid.value.keyword = value;
      grid.value.reload();
    }

    const formOptions: VxeFormProps = {
      titleWidth: 60,
      titleAlign: 'right',
      items: [
        {
          field: 'Name',
          title: '名稱',
          span: 24,
          className: "bg-grayscale-14 text-secondary",
          itemRender: {
            name: '$input',
            props: { placeholder: '請輸入文字', clearable: true },
            attrs: { type: 'text' }
          }
        },
        {
          field: 'Description',
          title: '說明',
          span: 24,
          className: "bg-grayscale-14 text-secondary",
          itemRender: {
            name: '$input',
            props: { placeholder: '請輸入文字', clearable: true },
            attrs: { type: 'text' }
          }
        },
        {
          title: '權限',
          span: 24,
          className: "bg-grayscale-14 text-secondary",
          slots: { default: 'form-permissions' }
        },
      ],
      rules: {
        Name: [{ type: 'string', required: true }]
      }
    }
    const itemData: any = ref({});
    const newItemModal = reactive({
      visible: false,
      show(id: any) {
        if (id) {
          model?.dispatch("role/find", id).then((data) => {
            itemData.value = data;
            newItemModal.visible = true;
          });
        }
        else {
          itemData.value = {};
          newItemModal.visible = true;
        }
      },
      submit(data: any) {
        console.log(data);
        if (data.Id) newItemModal.update(data);
        else newItemModal.insert(data);
      },
      async insert(item: any) {
        try {
          const temp = await model?.dispatch("role/insert", item);
          newItemModal.visible = false;
          grid.value.reload();
        } catch (error: any) {
          Cloudfun.send("error", error);
        }
      },
      async update(item: any) {
        try {
          const temp = await model?.dispatch("role/update", item)
          selectedRow.value = temp;
          newItemModal.visible = false;
          grid.value.reload();
        } catch (error: any) {
          Cloudfun.send("error", error);
        }
      }
    })

    const removeModal = reactive({
      visible: false,
      row: {} as any,
      show() {
        removeModal.visible = true;
        removeModal.row = selectedRow.value!;
      },
      async submit(item: any) {
        try {
          await model?.dispatch("role/delete", item.Id);
          selectedRow.value = null;
          removeModal.visible = false;
        } catch (error: any) {
          Cloudfun.send("error", error);
        }
      }
    })

    const onGridCellClick = ({ row, column }: { row: any, column: GridColumnOptions }) => {
      selectedRow.value = row;
    }
    watch(() => model?.state["role"]["reloadUI"], value => {
      if (!value) return;
      grid.value.reload();
      model!.state["role"]["reloadUI"] = false;
    })
    const permissionItems = computed(() => {
      const items: any[] = []
      for (const permission of Object.values(model?.enums.SystemPermission || {})) {
        if (permission.GroupName != null) {
          let group = items.find(e => e.name === permission.GroupName)
          if (!group) items.push(group = { name: permission.GroupName, items: [], order: 0 })
          group.items.push({ name: permission.Name, value: permission.Value, order: permission.Order })
          if (group.order < permission.Order) group.order = permission.Order
        } else items.push({ name: permission.Name, value: permission.Value, order: permission.Order })
      }
      return items.sort((a: any, b: any) => a.order - b.order)
    })

    onMounted(()=>{
      tabs.value = [
        { name: "USER", href: "/user", current: false, permission: permissions.includes('User') },
        { name: "ROLE", href: "/role", current: false, permission: permissions.includes('Role')},
        { name: "ACTION LOG", href: "/action-log", current: true, permission: permissions.includes('ActionLog')}
      ].filter((tab: any) => {
        return tab.permission === true;
      });
    })

    return {
      grid,
      gridOptions,
      selectedRow,
      onGridCellClick,
      formOptions,
      onSearch,
      newItemModal,
      removeModal,
      itemData,
      permissionItems,
      tabs,
      // toolbar: {
      //   canCreate: false,
      //   canDelete: false,
      //   canEdit: false,
      // },
    };
  },
});
